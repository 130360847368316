// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aarsmoede-js": () => import("./../src/pages/aarsmoede.js" /* webpackChunkName: "component---src-pages-aarsmoede-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kommende-aktiviteter-js": () => import("./../src/pages/kommende-aktiviteter.js" /* webpackChunkName: "component---src-pages-kommende-aktiviteter-js" */),
  "component---src-pages-medlemskab-js": () => import("./../src/pages/medlemskab.js" /* webpackChunkName: "component---src-pages-medlemskab-js" */),
  "component---src-pages-nyheder-js": () => import("./../src/pages/nyheder.js" /* webpackChunkName: "component---src-pages-nyheder-js" */),
  "component---src-pages-om-os-js": () => import("./../src/pages/om-os.js" /* webpackChunkName: "component---src-pages-om-os-js" */),
  "component---src-pages-tidligere-aktiviteter-js": () => import("./../src/pages/tidligere-aktiviteter.js" /* webpackChunkName: "component---src-pages-tidligere-aktiviteter-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

